import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { LinkedGroupConstants } from '../constants/linked-group.constants';
import { LinkLogicRole } from '../models/linkLogicRole';
import { StorageService } from './storage.service';

const API_URL = environment.apimBase;
@Injectable({
  providedIn: 'root'
})
export class LinklogicService {

  constructor(private storageService: StorageService, private http: HttpClient, private logging: LoggingService) {
  }

  public ApiGetMemberLinkLogic(memberUniqueKey: number) {
    return this.http
      .get<LinkLogicRole[]>(`${API_URL}/ihublinklogic/api/v1/linlogic?id=${memberUniqueKey}`)
      .pipe(catchError((err: HttpErrorResponse) => {
        this.logging.LogGenericError('linkLogicService',
          `Error retrieving link logic for member ${memberUniqueKey}`,
          err);
        return of([]);
      }));
  }

  public IsUserRole(roles: number[]): boolean {
    let linkLogicRoles: { linkLogicRoleValue: number }[] = [];
    try {
      linkLogicRoles = JSON.parse(this.storageService.linkLogicRoles);
      if (!linkLogicRoles || linkLogicRoles?.length === 0) {
        // TODO: fetch roles again from the server.
        return false;
      }

      return roles.some(
        (role: number) => {
          return linkLogicRoles.filter(r => r.linkLogicRoleValue === role).length > 0;
        });
    }
    catch (e) {
      this.logging.LogGenericError('LinkLogicService', 'JSON Parse error on getting user roles', e as Error);
      return false;
    }
  }


  public isMAMember() {
    return this.IsUserRole([LinkedGroupConstants.MEDICARE_ADVANTAGE]);
  }

  public isMedSupMember() {
    return this.IsUserRole([LinkedGroupConstants.FUTURE_MEDICARE_SUPP_MBR, LinkedGroupConstants.CURRENT_MEDICARE_SUPP_MBR])
  }


}
